import React from 'react'

import { itemIsInThePast, sortFromFutureToPast } from '../common/dateHandling'

export default class ItemTitles extends React.Component {
  render () {
    const {
      onItemClick,
      selected,
      isMobile,
      isPortrait,
      onRight,
      bottomPadding
    } = this.props
    let { items } = this.props
    const itemsHaveDates = items.some(item => item.date)
    // ensure they are sorted by date
    if (itemsHaveDates) {
      items = sortFromFutureToPast(items)
    }
    let seenFirstItemInPast = false

    return (
      <table>
        <tbody>
          {items &&
            items.map((item, i) => {
              const pastItem = itemIsInThePast(item)
              let className =
                'item' +
                (item === selected ? ' selected' : '') +
                (pastItem ? ' past' : '')
              if (isMobile && isPortrait && selected && item !== selected) {
                className += ' hidden'
              }
              let drawFuturePastDivider = false
              if (pastItem && !seenFirstItemInPast) {
                drawFuturePastDivider = true
                seenFirstItemInPast = true
              }

              const pastFutureDividerStyle = { transition: 'opacity 1s' }
              if (isMobile && isPortrait && selected) {
                pastFutureDividerStyle.opacity = 0
              }

              /*
                if we're in mobile portrait and an item is selected,
                then we should crop the top and bottom to be just around
                that item
              */

              return (
                <React.Fragment key={i}>
                  {drawFuturePastDivider && (
                    <tr style={pastFutureDividerStyle}>
                      <td />
                      <td style={{ textAlign: 'right' }}>
                        <div
                          style={{
                            color: 'red',
                            width: '10px',
                            transform: 'rotate(90deg)',
                            position: 'relative',
                            left: '-16px',
                            top: '-8px'
                          }}
                        >
                          &lt;&gt;
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr
                    onClick={e => onItemClick(item, e.currentTarget)}
                    style={{ cursor: 'pointer' }}
                    className={className}
                    id={'item_title_' + item.slug}
                  >
                    {itemsHaveDates && (
                      <td
                        style={{
                          paddingRight: '20px',
                          verticalAlign: 'top',
                          textAlign: 'right',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {item.startDateText || item.date}
                        <br />
                        {item.endDateText || item.endDate}
                      </td>
                    )}
                    <td
                      style={{
                        textAlign: onRight ? 'right' : 'left',
                        height: 44
                      }}
                    >
                      {item.title}
                      <br />
                      {item.title2 !== '=' && item.title2}
                    </td>
                  </tr>
                </React.Fragment>
              )
            })}
          <tr>
            <td height={bottomPadding}>&nbsp;</td>
          </tr>
        </tbody>
      </table>
    )
  }
}
