import React from 'react'
import { Swipeable } from 'react-swipeable'

import fileType from '../common/fileType.js'
import { transformCloudinaryUrlFitInBox } from '../common/transformCloudinaryUrl.js'
import { getVideoEmbedUrl, getVideoId } from '../common/youtube'

function getFadeInOut (percent) {
  return `transparent 0%, rgba(255, 255, 255, 1) ${percent}%, rgba(255, 255, 255, 1) ${100 -
    percent}%, transparent 100%`
}

function getFadeInOutStyle (doFade, angle, percent) {
  if (!doFade) {
    return {}
  }
  return {
    WebkitMaskImage: `linear-gradient(${angle}deg, ${getFadeInOut(percent)})`
  }
}

export default class Piece extends React.Component {
  constructor () {
    super()
    this.state = {
      devicePixelRatio: 1
    }
  }

  componentDidMount () {
    this.setState({
      devicePixelRatio: Math.min(window.devicePixelRatio, 1.2)
    })
  }

  renderMedia () {
    const {
      piece,
      width,
      height,
      onClick,
      onSwipedLeft,
      onSwipedRight
    } = this.props
    if (!piece) {
      return null
    }

    const fitInBox = url =>
      transformCloudinaryUrlFitInBox(
        url,
        width * this.state.devicePixelRatio,
        height * this.state.devicePixelRatio
      )

    const doFade = piece.fadeEdges || piece.fadeEdges === null
    const sizeStyle = {
      maxHeight: height,
      maxWidth: width,
      ...getFadeInOutStyle(doFade, 90, 10)
    }
    if (piece.type === 'media') {
      const type = fileType(piece.mediaUrl)
      if (type === 'IMAGE') {
        return (
          <Swipeable
            onSwipedLeft={onSwipedLeft}
            onSwipedRight={onSwipedRight}
          >
            <img
              src={fitInBox(piece.mediaUrl)}
              style={{ ...sizeStyle, cursor: 'pointer' }}
              onClick={onClick}
            />
          </Swipeable>
        )
      }
      if (type === 'VIDEO') {
        return (
          <video
            style={sizeStyle}
            src={fitInBox(piece.mediaUrl)}
            poster={fitInBox(piece.poster)}
            onClick={onClick}
            autoPlay
            loop
            controls
            muted
          />
        )
      }
    } else if (piece.type === 'youtube') {
      // parse the youtube url
      return (
        <iframe
          style={{ backgroundColor: 'transparent', ...sizeStyle }}
          width={(height * 560) / 315}
          height={height}
          src={
            getVideoEmbedUrl(piece.mediaUrl) +
            `?autoplay=1&loop=1&rel=1&playlist=${getVideoId(piece.mediaUrl)}`
          }
          frameBorder='0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      )
    }
  }

  render () {
    const { piece, width, height } = this.props
    const doFade = piece ? piece.fadeEdges || piece.fadeEdges === null : true
    return (
      <center style={{ height, width, objectFit: 'contain' }}>
        <div style={getFadeInOutStyle(doFade, 90, 10)}>
          <div style={getFadeInOutStyle(doFade, 1, 10)}>
            <div style={getFadeInOutStyle(doFade, -1, 10)}>
              {this.renderMedia()}
            </div>
          </div>
        </div>
      </center>
    )
  }
}
