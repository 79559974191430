import React from 'react'
import { timeFormat } from 'd3-time-format'

const fmt = timeFormat('%M:%S')

function formatTime (totalSeconds) {
  return fmt(totalSeconds * 1000)
}

const height = 20
const r = 5

export default class AudioPlayer extends React.Component {
  constructor () {
    super()
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.audioIsReady = false
  }

  handleMouseDown (e) {
    const r = e.currentTarget.getBoundingClientRect()
    this.setAudioPositionFromScreenPosition(e.clientX - r.left)
    e.stopPropagation()
  }

  handleMouseMove (e) {
    if (e.buttons > 0) {
      const r = e.currentTarget.getBoundingClientRect()
      this.setAudioPositionFromScreenPosition(e.clientX - r.left)
      e.stopPropagation()
    }
  }

  handleTouchStart (e) {
    console.log('got a touch start')
    const r = e.currentTarget.getBoundingClientRect()
    this.setAudioPositionFromScreenPosition(e.touches[0].clientX - r.left)
    e.stopPropagation()
  }

  handleTouchMove (e) {
    const r = e.currentTarget.getBoundingClientRect()
    this.setAudioPositionFromScreenPosition(e.touches[0].clientX - r.left)
    e.stopPropagation()
  }

  getWidth () {
    const { innerWidth } = this.props
    return innerWidth ? (innerWidth > 800 ? 700 : innerWidth - 50) : 700
  }

  setAudioPositionFromScreenPosition (x) {
    const { audio } = this.props
    const audioIsReady = audio && audio.duration() // audio.readyState > 3
    if (!audioIsReady) {
      console.log('no audio yet')
      return
    }
    const fraction = (x - r) / (this.getWidth() - 2 * r)
    audio.setCurrentTime(fraction * audio.duration())
    this.forceUpdate()
  }

  render () {
    const { title, src, onTitleClick, audio } = this.props
    let { duration } = this.props
    const playing = audio && !audio.paused()
    const audioIsReady = audio && !!audio.duration()// (this.audioIsReady =
    // this.audioIsReady || (audio && audio.readyState > 3))

    const audioIsLoading = audio && !audio.duration()

    const width = this.getWidth()
    let position = r
    if (!audioIsLoading && audioIsReady) {
      position = r + ((width - r * 2) * audio.currentTime()) / audio.duration()
    }

    // for some reason the duration gets turned into a number of minutes,
    // but only in the website - not in the cms admin page
    if (typeof (duration) === 'number') {
      const minutes = Math.floor(duration / 60)
      const seconds = duration % 60
      duration = `${minutes}:${seconds.toPrecision(2)}`
    }

    return (
      <>
        <div style={{ width, display: 'inline-block' }}>
          <div style={{ height: 20 }}>
            <span className='timecode' style={{ textAlign: 'left' }}>
              {audioIsLoading ? 'loading...' : (audio ? formatTime(audio.currentTime()) : formatTime(0))}
            </span>
            <span
              onClick={() => onTitleClick(src)}
              className='track-title'
              style={{ width: width - 160, color: playing ? 'red' : null }}
            >
              {title}
            </span>
            <span className='timecode' style={{ textAlign: 'right' }}>
              {duration}
            </span>
          </div>
          <svg
            width={width}
            height={height}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            style={{ cursor: 'pointer' }}
          >
            <line
              y1={height / 2}
              y2={height / 2}
              x1={0}
              x2={width}
              stroke='white'
            />
            <circle fill='red' r={r} cy={height / 2} cx={position || 0} />
          </svg>
        </div>
      </>
    )
  }
}
