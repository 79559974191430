import { timeParse } from 'd3-time-format'
const dateFormat = '%d.%m.%y'
const dateParser = timeParse(dateFormat)

function itemIsInThePast (item) {
  if (!item.date) {
    return false
  }
  return new Date() > dateParser(item.date)
}

function sortFromFutureToPast (list) {
  return list.sort((a, b) => {
    return dateParser(a.date) < dateParser(b.date) ? 1 : -1
  })
}

// first item in the future if any, otherwise the most recent one
function getDefaultItem (list) {
  const sortedList = sortFromFutureToPast(list)
  let itemInFuture = null
  for (const item of sortedList) {
    if (!itemIsInThePast(item)) {
      itemInFuture = item
    } else {
      // now we have past items
      if (itemInFuture) {
        return itemInFuture
      } else {
        return sortedList[0]
      }
    }
  }
}

export { dateParser, itemIsInThePast, getDefaultItem, sortFromFutureToPast }
