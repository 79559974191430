const IMAGE_QUALITY = 'auto'

function parseCloudinaryUrl (url) {
  if (!url) {
    return null
  }

  const re = /https:\/\/res\.cloudinary\.com\/([a-z]+)\/([a-z]+)\/upload\/v[0-9]+\/(.+)/
  const match = url.match(re)
  return match ? { cloudName: match[1], type: match[2], path: match[3] } : null
}

function round (number, rounding) {
  return Math.round(Math.ceil(number / rounding) * rounding)
}

function transformCloudinaryUrlForHeight (url, height, rounding = 200) {
  if (!url) {
    return url
  }
  // if the url is a gif, then we assume it's an animation and don't try to change it
  if (url.endsWith('.gif')) {
    return url
  }
  height = round(height, rounding)
  const parsed = parseCloudinaryUrl(url)
  if (!parsed) {
    return url
  }
  const { cloudName, path, type } = parsed
  return `https://res.cloudinary.com/${cloudName}/${type}/upload/q_${IMAGE_QUALITY},h_${height},fl_progressive:steep/${path}`
}

function transformCloudinaryUrlForWidth (url, width, rounding = 200) {
  if (!url) {
    return url
  }
  // if the url is a gif, then we assume it's an animation and don't try to change it
  if (url.endsWith('.gif')) {
    return url
  }
  width = round(width, rounding)
  const parsed = parseCloudinaryUrl(url)
  if (!parsed) {
    return url
  }
  const { cloudName, path, type } = parsed
  return `https://res.cloudinary.com/${cloudName}/${type}/upload/q_${IMAGE_QUALITY},w_${width},fl_progressive:steep/${path}`
}

function transformCloudinaryUrlFitInBox (url, width, height, rounding = 200) {
  if (!url) {
    return url
  }
  // if the url is a gif, then we assume it's an animation and don't try to change it
  if (url.endsWith('.gif')) {
    return url
  }
  width = round(width, rounding)
  height = round(height, rounding)
  const parsed = parseCloudinaryUrl(url)
  if (!parsed) {
    return url
  }
  const { cloudName, path, type } = parsed
  return `https://res.cloudinary.com/${cloudName}/${type}/upload/q_${IMAGE_QUALITY},w_${width},h_${height},c_fit,fl_progressive:steep/${path}`
}

export {
  transformCloudinaryUrlForHeight,
  transformCloudinaryUrlForWidth,
  transformCloudinaryUrlFitInBox
}
