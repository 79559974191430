/*
  A wrapper around window.Audio which will load the
  metadata and then pause loading of data until
  the audio is requested to play
*/

import { addProperties, addEvents } from 'properties-and-events'

export default class MdAudio {
  constructor (src) {
    this.src = src
    this._audio = new window.Audio(src)
    this._audio.preload = 'metadata'
    this._duration = null

    addEvents(this, 'change', 'ended')
    addProperties(this, 'duration')

    this._audio.addEventListener('loadedmetadata', () => {
      this._duration = this._audio.duration
      this.fire('change')
    })

    this._audio.addEventListener('timeupdate', () => {
      this.fire('change')
    })

    this._audio.addEventListener('ended', () => {
      this.fire('ended')
    })
  }

  _createPlayingAudio () {
    this._audio.addEventListener('timeupdate', () => {
      this.fire('change')
    })
  }

  play () {
    this._audio.play()
  }

  pause () {
    if (this._audio) {
      this._audio.pause()
    }
  }

  paused () {
    if (this._audio) {
      return this._audio.paused
    } else {
      return true
    }
  }

  currentTime () {
    return this._audio ? this._audio.currentTime : 0
  }

  setCurrentTime (time) {
    if (!this._audio) {
      this._createPlayingAudio()
    }
    this._audio.currentTime = time
  }
}
