import React from 'react'
import AudioPlayer from './AudioPlayer'
import MdAudio from '../MdAudio'

function getImageUrlFromSrc (src, sounds) {
  for (const sound of sounds) {
    if (sound.src === src) {
      return sound.mediaUrl
    }
  }
  return null
}

export default class AudioPlayers extends React.Component {
  constructor () {
    super()
    this.handleTitleClick = this.handleTitleClick.bind(this)
    this.audioBySrc = {} // audio are the actual Audio objects
  }

  createAudio (src) {
    const audio = new MdAudio(src)
    audio.on('change', () => {
      this.forceUpdate()
    })
    audio.on('ended', () => {
      const { sounds } = this.props
      const srcs = sounds.map(s => s.src)
      const i = srcs.indexOf(src)
      let j = i + 1
      if (j > srcs.length - 1) {
        j = 0
      }
      this.handleTitleClick(srcs[j])
    })
    this.audioBySrc[src] = audio
  }

  handleTitleClick (src) {
    if (!(src in this.audioBySrc)) {
      this.createAudio(src)
    }

    const { sounds, onImageSelect } = this.props
    const playing = sounds.filter(sound => {
      const audio = this.audioBySrc[sound.src]
      return audio && !audio.paused()
    })
    for (const sound of playing) {
      const audio = this.audioBySrc[sound.src]
      if (audio) {
        audio.pause()
      }
    }
    const isStopping = playing.some(sound => sound.src === src)
    if (!isStopping) {
      this.audioBySrc[src].play()
    }
    onImageSelect(getImageUrlFromSrc(src, sounds))
    this.forceUpdate()
  }

  render () {
    const { sounds, innerWidth } = this.props
    return (
      sounds &&
      sounds.map(({ title, src, duration }, i) => {
        const audio = this.audioBySrc[src]
        return (
          <div key={i}>
            <AudioPlayer
              title={title}
              src={src}
              duration={duration}
              audio={audio}
              onTitleClick={this.handleTitleClick}
              innerWidth={innerWidth}
            />
          </div>
        )
      })
    )
  }
}
