import React from 'react'
import Markdown from 'react-markdown'
import breaks from 'remark-breaks'

export default class Description extends React.Component {
  constructor ({ isMobile, isPortrait }) {
    super()
    this.handleWheel = this.handleWheel.bind(this)
    this.main = React.createRef()
    this.state = {
      opacity: isMobile && isPortrait ? 0 : 1
    }
  }

  handleWheel (e) {
    e.stopPropagation()
  }

  componentDidMount () {
    this.main.current.addEventListener('wheel', this.handleWheel)
    const { isMobile, isPortrait } = this.props
    if (isMobile && isPortrait) {
      setTimeout(() => {
        this.setState({ opacity: 1 })
      }, 1000)
    }
  }

  componentWillUnmount () {
    this.main.current.removeEventListener('wheel', this.handleWheel)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.description !== this.props.description) {
      this.main.current.scrollTop = 0
    }
  }

  render () {
    let {
      onRight,
      description,
      innerHeight,
      innerWidth,
      isMobile,
      isPortrait,
      renderPieces
    } = this.props
    const { opacity } = this.state
    const top = isMobile ? (isPortrait ? 178 : 35) : 140
    const height = isMobile ? innerHeight - top - 110 : innerHeight - 330
    const paddingTop = isMobile ? 0 : 50
    const left = isMobile ? 20 : 60
    const right = isMobile ? 20 : 60
    const width = isMobile
      ? isPortrait
        ? innerWidth - 40
        : innerWidth / 3
      : 285

    if (isMobile && isPortrait) {
      onRight = false
    }

    let style = {
      // border: '1pt dashed red',
      position: 'absolute',
      top,
      width,
      height,
      paddingTop,
      paddingBottom: '50px',
      overflowY: 'scroll',
      WebkitMaskImage:
        'linear-gradient(transparent, white 5%, white 95%, transparent)',
      zIndex: isMobile ? 5 : 0,
      transition: 'opacity 1s',
      opacity,
      scrollbarWidth: 'none'
    }

    if (onRight) {
      style = {
        right,
        textAlign: 'right',
        ...style
      }
    } else {
      style = {
        left,
        textAlign: 'left',
        ...style
      }
    }

    let pieces = null
    if (isMobile && isPortrait && renderPieces) {
      pieces = renderPieces(true)
    }

    description = description.replace(/\n/g, '&nbsp;\n')

    return (
      <div ref={this.main} style={style}>
        {pieces}
        <Markdown plugins={[breaks]} children={description} />
      </div>
    )
  }
}
