export function getVideoId (url) {
  return url.match(/[A-Za-z0-9]+$/)[0]
}

export function getVideoEmbedUrl (url) {
  const id = getVideoId(url)
  return `https://www.youtube.com/embed/${id}`
}

export function getVideoThumbnailUrl (url) {
  const id = getVideoId(url)
  return `https://img.youtube.com/vi/${id}/default.jpg`
}
