export default function ({
  innerHeight,
  innerWidth,
  isMobile,
  isPortrait,
  inline
}) {
  const top = isMobile && isPortrait ? 165 : innerHeight / 3.8

  const width = Math.round(
    isMobile && isPortrait ? innerWidth - 40 : innerWidth * 0.6
  )
  const height = Math.min(
    Math.round(innerHeight / (isPortrait ? 2.6 : 1.7)),
    (width * 3) / 4
  )

  const style = {
    position: inline ? 'inline' : 'absolute',
    color: 'red',
    width,
    height,
    right: (innerWidth - width) / 2,
    top,
    zIndex: isMobile ? 10 : 0
  }

  if (isMobile && isPortrait) {
    style.top = 165
  } else {
    style.bottom = innerHeight / 6.5
  }

  return style
}
