export default function createTransition (
  {
    startValue,
    endValue,
    ease,
    setter,
    onEnd,
    duration,
    delay = 0
  }) {
  let t = 0
  const tToValue = (t) => {
    if (t < delay) {
      return startValue
    }
    const d = endValue - startValue
    return startValue + d * ease((t - delay) / duration)
  }

  const tick = (delta) => {
    t += delta
    t = Math.min(duration + delay, t)
    setter(tToValue(t))
    if (t >= duration + delay) {
      if (onEnd) {
        onEnd()
      }
    }
  }

  return { tick }
}