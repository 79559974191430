export default (handler) => {
  let lastWidth = null
  let lastHeight = null

  const resizeHandler = () => {
    const w = window.innerWidth
    const h = window.innerHeight

    if (h < 800 && w === lastWidth) {
      if (h - lastHeight > 100) {
        handler(false)
      }
      if (lastHeight - h > 100) {
        handler(true)
      }
    }

    lastWidth = w
    lastHeight = h
  }

  window.addEventListener('resize', resizeHandler)

  setInterval(resizeHandler, 1000)

  // return a cleanup function
  return () => {
    window.removeEventListener('resize', resizeHandler)
  }
}