import React from 'react'

export default class DownloadStrean extends React.Component {
  render () {
    const { isOpen, onToggleDownloadStream, innerWidth } = this.props

    const imgWidth = Math.min(100, innerWidth / 5)

    const imgStyle = {
      width: imgWidth,
      marginRight: '5px',
      marginLeft: '5px'
    }

    return (
      <center>
        <div
          style={{
            height: isOpen ? 100 : 0,
            overflowY: 'hidden',
            transition: 'height 0.5s'
          }}
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <a
                    target='_new'
                    href='https://beta.resonate.is/artists/10828'
                  >
                    <img style={imgStyle} src='/img/social/resonate.png' />
                  </a>
                </td>
                <td>
                  <a
                    target='_new'
                    href='https://abnormmusic.bandcamp.com/releases'
                  >
                    <img style={imgStyle} src='/img/social/bandcamp.png' />
                  </a>
                </td>
                <td>
                  <a
                    target='_new'
                    href='https://listen.tidal.com/album/125872610'
                  >
                    <img
                      style={{ position: 'relative', top: -1, ...imgStyle }}
                      src='/img/social/tidal.png'
                    />
                  </a>
                </td>
                <td>
                  <a
                    target='_new'
                    href='https://music.apple.com/us/artist/abnorm/1491734484'
                  >
                    <img style={imgStyle} src='/img/social/itunes-store.png' />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    target='_new'
                    href='https://www.deezer.com/en/artist/86437692'
                  >
                    <img style={imgStyle} src='/img/social/deezer.png' />
                  </a>
                </td>
                <td>
                  <a
                    target='_new'
                    href='https://open.spotify.com/album/5P2daPld0I14tRI0mIC0ZN'
                  >
                    <img style={imgStyle} src='/img/social/spotify.png' />
                  </a>
                </td>
                <td>
                  <a
                    target='_new'
                    href='https://music.apple.com/us/artist/abnorm/1491734484'
                  >
                    <img style={imgStyle} src='/img/social/apple-music.png' />
                  </a>
                </td>
                <td>
                  <a
                    target='_new'
                    href='https://www.youtube.com/channel/UCfHotbp79I6XOL3jvzEVPOw'
                  >
                    <img style={imgStyle} src='/img/social/youtube.png' />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span
          onClick={onToggleDownloadStream}
          className='track-title'
          style={{
            display: 'inline-block',
            paddingTop: '20px',
            borderBottom: '1pt solid',
            cursor: 'pointer'
          }}
        >
          download / stream
        </span>
      </center>
    )
  }
}
