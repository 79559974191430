import React from 'react'
import { scaleLinear } from 'd3-scale'

const titleAspectRatio = 2878 / 524

export default class Title extends React.Component {
  constructor () {
    super()
    this.state = {
      yScale: 1
    }
  }

  getSquishRatio () {
    const { isMobile, isPortrait } = this.props
    return isMobile && isPortrait ? 0.75 : 0.35
  }

  getTextHeight () {
    const { innerWidth } = this.props
    return innerWidth ? innerWidth / titleAspectRatio : 300
  }

  getTitleCenteredTop () {
    const textHeight = this.getTextHeight()
    const innerHeight = this.props.innerHeight || 700
    return (innerHeight - textHeight) / 2 - 80
  }

  getTitleMaxTop () {
    const textHeight = this.getTextHeight()
    const innerHeight = this.props.innerHeight || 700
    return innerHeight - textHeight * this.getSquishRatio()
  }

  getSquishScale () {
    const squishRatio = this.getSquishRatio()
    return scaleLinear()
      .domain([-1, -0.5, 0.5, +1])
      .range([squishRatio, 1, 1, squishRatio])
      .clamp(true)
  }

  getTopScale () {
    const centeredTop = this.getTitleCenteredTop()
    const maxTop = this.getTitleMaxTop()
    return scaleLinear()
      .domain([-1, 0, +1])
      .range([0, centeredTop, maxTop])
      .clamp(true)
  }

  render () {
    const { onClick, position } = this.props
    const topScale = this.getTopScale()
    const yScale = this.getSquishScale()(position)
    const textHeight = this.getTextHeight()
    const top = topScale(position)

    const svgStyle = {
      width: '100%',
      height: textHeight + 'px',
      backgroundColor: 'transparent',
      position: 'absolute',
      top,
      cursor: 'pointer',
      transform: `scale(1, ${yScale})`,
      transformOrigin: 'top'
    }

    const textStyle = {
      fontFamily: 'AA',
      fill: '#411b26',
      stroke: 'transparent',
      fontSize: '201px'
    }

    return (
      <svg
        onClick={onClick}
        preserveAspectRatio='xMidYMin meet'
        viewBox='0 0 600 10'
        style={svgStyle}
      >
        <text
          style={textStyle}
          width='1000'
          height={textHeight}
          y='100'
          x='-18'
        >
          ABNORM
        </text>
      </svg>
    )
  }
}
