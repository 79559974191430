import React from 'react'
import { timer } from 'd3-timer'
import { easePolyInOut } from 'd3-ease'
import createTransition from '../common/createTransition'

const TRANSITION_DURATION = 2000

const fullScreenDivStyle = {
  top: 0,
  left: 0,
  position: 'fixed',
  width: '100vw',
  height: '100vh'
}

function createStaticBackground (url) {
  return (opacity) => {
    return (
      <div
        key={url}
        style={{
          ...fullScreenDivStyle,
          opacity,
          backgroundImage: `url(${url})`,
          backgroundSize: 'cover'
        }}
      />
    )
  }
}

// const backgrounds = {
//   redDots: createStaticBackground('/img/backgrounds/red-dots-on-transparent.png'),
//   redCircles: createStaticBackground('/img/backgrounds/red-circles-on-transparent.png'),
//   blueCircles: createStaticBackground('/img/backgrounds/blue-circles-on-transparent.png'),
//   blueDots: createStaticBackground('/img/backgrounds/blue-dots-on-transparent.png')
// }

const backgrounds = {
  redDots: createStaticBackground('/img/backgrounds/red-dots.jpg'),
  redCircles: createStaticBackground('/img/backgrounds/red-circles.jpg'),
  blueCircles: createStaticBackground('/img/backgrounds/blue-circles.jpg'),
  blueDots: createStaticBackground('/img/backgrounds/blue-dots.jpg')
}

const backgroundNodes = {
  home: {
    blueDots: 0.7,
    redDots: 0.15
  },
  about: {
    blueDots: 0.5,
    redDots: 0.5
  },
  time: {
    blueDots: 0.6,
    blueCircles: 0.7
  },
  sound: {
    redDots: 0.2,
    blueDots: 0.5,
    redCircles: 0.3
  },
  body: {
    blueDots: 0.6,
    redCircles: 0.7
  }
}

const backgroundOpacities = JSON.parse(JSON.stringify(backgroundNodes.home))

// so that we can have a definite stacking order for them
const backgroundOrder = [
  'blueDots',
  'blueCircles',
  'redCircles',
  'redDots'
]

const backgroundTransitions = {}

export default class Backgrounds extends React.Component {
  constructor () {
    super()
    this.state = {
      scroll: 0
    }
    // this.tick = this.tick.bind(this)
    this.lastTickTime = null
  }

  componentDidMount () {
    window.addEventListener('scroll', () => {
      this.setState({
        scroll: window.scrollY
      })
    })

    let lastTick = 0
    timer((elapsed) => {
      const d = elapsed - lastTick
      lastTick = elapsed
      const transitions = Object.values(backgroundTransitions).filter((t) => t)
      if (transitions.length > 0) {
        for (const transition of transitions) {
          if (transition) {
            transition.tick(d)
          }
        }
        this.forceUpdate()
      }
    })
  }

  componentDidUpdate (prevProps) {
    const { position } = this.props

    if (position === undefined || position === null) {
      return
    }

    if (prevProps.position !== position) {
      // create the transitions to the new background states
      for (const name of backgroundOrder) {
        const startValue = backgroundOpacities[name] || 0
        const endValue = backgroundNodes[position][name] || 0

        if (startValue !== endValue) {
          backgroundTransitions[name] = createTransition({
            startValue,
            endValue,
            ease: easePolyInOut.exponent(0.4),
            duration: TRANSITION_DURATION,
            delay: 0,
            setter: (value) => {
              backgroundOpacities[name] = value
            },
            onEnd: () => {
              backgroundTransitions[name] = null
            }
          })
        }
      }
    }
  }

  render () {
    return (
      <div id='#backgrounds'>
        {backgroundOrder.map((name) => (
          backgrounds[name](backgroundOpacities[name] || 0)
        ))}
      </div>
    )
  }
}
