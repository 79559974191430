import React from 'react'
import { scaleLinear } from 'd3-scale'
import Markdown from 'react-markdown'
import breaks from 'remark-breaks'

function blankIfEqualsSign(value) {
  return value === '=' || value === '\\=' ? '' : value
}

export default class AboutSection extends React.Component {
  constructor() {
    super()
    this.state = {
      contentHeight: 300,
      emailSubmitted: false,
      emailSubmissionError: false
    }
    this.main = React.createRef()
    this.email = React.createRef()
    this.handleEmailSubmission = this.handleEmailSubmission.bind(this)
    this.setContentHeight = this.setContentHeight.bind(this)
  }

  setContentHeight() {
    this.setState({
      contentHeight: this.main.current?.offsetHeight
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.setContentHeight)
    this.setContentHeight()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setContentHeight)
  }

  async handleEmailSubmission() {
    if (!this.email.current.value) {
      return
    }
    const url =
      './.netlify/functions/add-email-to-list?email=' + this.email.current.value
    const response = await window.fetch(url)
    if (response.ok) {
      // if HTTP-status is 200-299
      // get the response body (the method explained below)
      const text = await response.text()
      if (text.startsWith('added successfully')) {
        this.setState({ emailSubmitted: true })
      } else {
        this.setState({ emailSubmissionError: true })
      }
    } else {
      console.log('HTTP-Error: ' + response.status)
      this.setState({ emailSubmissionError: true })
    }
  }

  getTopScale() {
    const { innerHeight } = this.props
    const { contentHeight } = this.state
    const h = Math.min(contentHeight, innerHeight - 200)
    const outOfView = -contentHeight - 100
    const centre = (this.props.innerHeight || 700) / 2 - h / 2

    return scaleLinear()
      .domain([-1, 0])
      .range([outOfView, centre])
      .clamp(true)
  }

  subscribeSection() {
    const { isMobile } = this.props
    const { emailSubmitted, emailSubmissionError } = this.state
    return (
      <>
        {emailSubmissionError &&
          'Sorry, there was an error recording your email'}
        {emailSubmitted && !emailSubmissionError && 'Thank you'}
        {!emailSubmitted && !emailSubmissionError && (
          <>
            email:{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              <input
                type='email'
                style={{ fontSize: '16px' }}
                ref={this.email}
                onFocus={() => {
                  window.emailFieldHasFocus = true
                  // window.setTimeout(function () {
                  //   if (isMobile) {
                  //     setTimeout(() => {
                  //       console.log('scrolling to 250 because the email field just got focus')
                  //       window.scrollTo(0, 250)
                  //     }, 20)
                  //   }
                  // }, 0)
                }}
                onBlur={() => {
                  window.emailFieldHasFocus = false
                  window.scrollTo(0, 0)
                }}
              />
              <button onClick={this.handleEmailSubmission}>submit</button>
            </span>
          </>
        )}
      </>
    )
  }

  prepareMarkdown(content) {
    const s = '<subscribe />'
    content = blankIfEqualsSign(content)
    if (content.includes(s)) {
      const parts = content.split(s)
      return (
        <>
          <Markdown plugins={[breaks]}>
            {parts[0].replace(/\n/g, '&nbsp;\n')}
          </Markdown>
          {this.subscribeSection()}
          <Markdown plugins={[breaks]}>
            {parts[1].replace(/\n/g, '&nbsp;\n')}
          </Markdown>
        </>
      )
    } else {
      return (
        <Markdown plugins={[breaks]}>
          {content.replace(/\n/g, '&nbsp;\n')}
        </Markdown>
      )
    }
  }

  render() {
    const {
      position,
      columns,
      isMobile,
      isPortrait,
      innerHeight,
      innerWidth
    } = this.props

    const { contentHeight } = this.state

    const top = this.getTopScale()(position)

    const sidePadding = isMobile && isPortrait ? 10 : 30

    const containerStyle = {
      WebkitMaskImage:
        'linear-gradient(transparent, white 5%, white 95%, transparent)',
      height: Math.min(innerHeight - 200, contentHeight),
      overflowY: 'auto',
      width: innerWidth
    }

    const portraitStyle = {
      zIndex: isMobile ? 5 : 0,
      transition: 'opacity 1s',
      scrollbarWidth: 'none',
      verticalAlign: 'top',
      paddingTop: 0,
      paddingBottom: 40
    }

    return (
      <div style={{ position: 'absolute', top, ...containerStyle }}>
        <table
          style={{ paddingLeft: sidePadding, paddingRight: sidePadding }}
          padding={this.state.padding}
        >
          <tbody ref={this.main}>
            {!(isMobile && isPortrait) && (
              <tr>
                <td width='33%' style={{ verticalAlign: 'top' }}>
                  {this.prepareMarkdown(columns[0])}
                </td>
                <td
                  width='33%'
                  style={{ verticalAlign: 'top', paddingLeft: 30 }}
                >
                  {this.prepareMarkdown(columns[1])}
                </td>
                <td
                  width='33%'
                  style={{ verticalAlign: 'top', paddingLeft: 30 }}
                >
                  {this.prepareMarkdown(columns[2])}
                </td>
              </tr>
            )}
            {isMobile && isPortrait && (
              <tr>
                <td style={portraitStyle}>
                  {this.prepareMarkdown(columns[0])}
                  {this.prepareMarkdown(columns[1])}
                  {this.prepareMarkdown(columns[2])}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
