import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import IndexPageTemplate from '../templates/IndexPageTemplate'

function lowerCaseWithUnderscores (s) {
  return s.toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '_')
    .replace(/[^\x20-\x7E]/g, '')
}

function itemSlug (item) {
  let s = ''
  if (item.date) {
    s = lowerCaseWithUnderscores(item.date) + '_'
  }
  s += lowerCaseWithUnderscores(item.title)
  if (item.title2) {
    s += '_' + lowerCaseWithUnderscores(item.title2)
  }
  return s
}

function addSlugsToItems (items) {
  for (const item of items) {
    item.slug = itemSlug(item)
  }
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { timeItems, sounds, bodyItems } = frontmatter

  addSlugsToItems(timeItems)
  addSlugsToItems(sounds)
  addSlugsToItems(bodyItems)

  return (
    <Layout>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { }) {
      frontmatter {
        about1,
        about2,
        about3,
        timeItems {
          date,
          startDateText,
          endDate,
          endDateText,
          title,
          title2,
          description,
          pieces {
            type,
            mediaUrl,
            fadeEdges
          }
        }
        sounds {
          title
          src,
          mediaUrl,
          duration
        }
        bodyItems {
          title,
          title2,
          description,
          pieces {
            type,
            mediaUrl,
            fadeEdges
          }
        }
      }
    }
  }
`
