import React from 'react'

import Piece from './Piece'
import getPieceStyle from '../common/getPieceStyle'
import fileType from '../common/fileType.js'

const navButtonStyle = {
  cursor: 'pointer'
}

export default class Pieces extends React.Component {
  constructor ({ pieces, showLastPieceFirst, isMobile, isPortrait }) {
    super()
    this.state = {
      pieceIndex: showLastPieceFirst ? pieces.length - 1 : 0,
      opacity: isMobile && isPortrait ? 0 : 1
    }
    this.changePiece = this.changePiece.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  changePiece (move) {
    const { onItemChange } = this.props
    const lastPieceIndex = this.props.pieces.length - 1
    let { pieceIndex } = this.state
    let itemChange = 0
    pieceIndex += move
    if (pieceIndex < 0) {
      pieceIndex = 0
      itemChange = -1
    }
    if (pieceIndex > lastPieceIndex) {
      pieceIndex = lastPieceIndex
      itemChange = +1
    }
    this.setState({ pieceIndex })
    if (itemChange !== 0 && onItemChange) {
      onItemChange(itemChange)
    }
  }

  handleKeyDown (e) {
    if (e.repeat) {
      return
    }
    switch (e.code) {
      case 'ArrowLeft':
        this.changePiece(-1)
        e.preventDefault()
        break
      case 'ArrowRight':
        this.changePiece(+1)
        e.preventDefault()
        break
      default:
        // do nothing
        break
    }
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleKeyDown)
    const { isMobile, isPortrait } = this.props
    if (isMobile && isPortrait) {
      setTimeout(() => {
        this.setState({ opacity: 1 })
      }, 1000)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.pieces !== this.props.pieces) {
      const { pieces, showLastPieceFirst } = this.props
      this.setState({ pieceIndex: showLastPieceFirst ? pieces.length - 1 : 0 })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  render () {
    const {
      pieces,
      innerHeight,
      innerWidth,
      isMobile,
      isPortrait,
      inline
    } = this.props
    const { pieceIndex, opacity } = this.state
    if (!pieces || !pieces.length) {
      return null
    }
    const piece = pieces[this.state.pieceIndex]

    let pieceStyle = getPieceStyle({
      inline,
      innerHeight,
      innerWidth,
      isMobile,
      isPortrait
    })
    pieceStyle = { transition: 'opacity 1s', opacity, ...pieceStyle }

    // const otherImagePieces = pieces.filter(p => {
    //   if (piece.type !== 'media') {
    //     return false
    //   }
    //   const type = fileType(piece.mediaUrl)
    //   return type === 'IMAGE'
    // })

    const nextImage = pieces.find(
      (p, i) =>
        i > pieceIndex &&
        p.type === 'media' &&
        fileType(piece.mediaUrl) === 'IMAGE'
    )

    return (
      <div style={pieceStyle}>
        <Piece
          key={piece}
          piece={piece}
          height={pieceStyle.height}
          width={pieceStyle.width}
          onClick={() => this.changePiece(+1)}
          onSwipedLeft={() => this.changePiece(+1)}
          onSwipedRight={() => this.changePiece(-1)}
        />
        {nextImage && (
          <div style={{ display: 'none' }}>
            <Piece
              key={nextImage.mediaUrl}
              piece={nextImage}
              height={pieceStyle.height}
              width={pieceStyle.width}
            />
          </div>
        )}

        <div
          style={{
            textAlign: 'right',
            position: 'relative',
            top: -10,
            marginRight: isPortrait ? 0 : innerWidth / 8
          }}
        >
          <span style={navButtonStyle} onClick={() => this.changePiece(-1)}>
            &lt;
          </span>
          {pieceIndex + 1}/{pieces.length}
          <span style={navButtonStyle} onClick={() => this.changePiece(+1)}>
            &gt;
          </span>
        </div>
      </div>
    )
  }
}
