import React from 'react'

export default class ContentBox extends React.Component {
  constructor () {
    super()
    this.handleWheel = this.handleWheel.bind(this)
    this.main = React.createRef()
  }

  handleWheel (e) {
    if (this.props.preventScrolling) {
      e.preventDefault()
    }
    e.stopPropagation()
  }

  componentDidMount () {
    this.main.current.addEventListener('wheel', this.handleWheel)
  }

  componentWillUnmount () {
    this.main.current.removeEventListener('wheel', this.handleWheel)
  }

  render () {
    const {
      innerHeight,
      children,
      isMobile,
      isPortrait,
      fullHeight,
      preventScrolling
    } = this.props

    const height = (isMobile) ? (fullHeight || !isPortrait ? innerHeight - 180 : innerHeight / 3 - 80) : innerHeight - 300

    const style = {
      height,
      overflowY: preventScrolling ? 'hidden' : 'scroll',
      paddingTop: '30px',
      paddingBottom: '30px',
      WebkitMaskImage: 'linear-gradient(transparent, white 5%, white 95%, transparent)',
      scrollbarWidth: 'none'
    }

    return (
      <div
        ref={this.main}
        style={style}
      >
        {children}
        <br />
      </div>
    )
  }
}
