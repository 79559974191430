import '../../static/Abnorm.css'

import React from 'react'
import { scaleLinear } from 'd3-scale'
import { timer } from 'd3-timer'
import { easeQuad } from 'd3-ease'

import History from 'hash-history'

import Title from './Title'
import AudioPlayers from './AudioPlayers'
import Backgrounds from './Backgrounds'
import AboutSection from './AboutSection'
import ItemTitles from './ItemTitles'
import ContentBox from './ContentBox'
import Pieces from './Pieces'
import Piece from './Piece'
import Description from './Description'
import DownloadStream from './DownloadStream'

import { getDefaultItem } from '../common/dateHandling'
import getPieceStyle from '../common/getPieceStyle'
import createTransition from '../common/createTransition'

import setupSoftKeyboardDetector from '../setupSoftKeyboardDetector'

// import SizeDebugger from './SizeDebugger'

const titleStyle = {
  position: 'absolute',
  height: 'fit-content'
}

const timeTitleStyle = {
  left: '10px',
  ...titleStyle
}

const soundTitleStyle = {
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 'fit-content',
  ...titleStyle
}

const bodyTitleStyle = {
  right: '10px',
  ...titleStyle
}

const sectionsInOrder = ['about', 'home', 'time', 'sound', 'body']
const lowerSections = ['time', 'sound', 'body']

const TITLE_FROM_TOP = 120
const TITLE_FROM_TOP_MOBILE_PORTRAIT = 60
const TITLE_FROM_TOP_MOBILE_LANDSCAPE = 40
const TITLE_FROM_BOTTOM = 70
const SPACING_BETWEEN_TITLE_AND_CONTENT = 100
const SPACING_BETWEEN_TITLE_AND_CONTENT_MOBILE_PORTRAIT = 60
const SPACING_BETWEEN_TITLE_AND_CONTENT_MOBILE_LANDSCAPE = 60
const TITLE_FROM_BOTTOM_MOBILE_PORTRAIT = 40
const TITLE_BELOW_CONTENT_SPACING = 0

const TRANSITION_DURATION = 1200

function setTop (currentValue, newInnerHeight) {
  if (!currentValue) {
    return newInnerHeight + 100
  }
  return currentValue
}

export default class Abnorm extends React.Component {
  constructor () {
    super()

    this.main = React.createRef()
    this.about = React.createRef()
    this.timeContent = React.createRef()
    this.soundContent = React.createRef()
    this.bodyContent = React.createRef()

    this.removeTransition = this.removeTransition.bind(this)

    this.refsBySection = {
      time: this.timeContent,
      sound: this.soundContent,
      body: this.bodyContent
    }

    this.controllingScrolling = false
    this.scrollDestination = null
    this.transitionsBySection = {
      time: null,
      sound: null,
      body: null,
      title: null,
      about: null
    }
    this.headingHome = false

    const contentTops = (this.contentTops = {
      time: 1, // 1 is content off the bottom, 0 is in place for reading
      sound: 1, // same as time
      body: 1, // same as body
      title: 0, // zero is the centered position, -1 at the top and +1 at the bottom
      about: -1 // similar to title, zero is the centered position
    })

    this.currentSection = 'home'

    this.state = {
      innerWidth: 1440,
      innerHeight: 1300,
      selectedItem: null,
      showLastPieceFirst: false,
      isMobile: false,
      isPortrait: false,
      downloadStreamIsOpen: false,
      sizesAreSet: false
    }

    // contentTops.time = setTop(contentTops.time, this.state.innerHeight)
    // contentTops.sound = setTop(contentTops.sound, this.state.innerHeight)
    // contentTops.body = setTop(contentTops.body, this.state.innerHeight)

    this.handleItemClick = this.handleItemClick.bind(this)
    this.changeSection = this.changeSection.bind(this)
    this.handleItemChange = this.handleItemChange.bind(this)
    this.handleImageForAudioSelect = this.handleImageForAudioSelect.bind(this)
    this.handleToggleDownloadStream = this.handleToggleDownloadStream.bind(this)
  }

  componentDidMount () {
    const doc = this.main.current.ownerDocument
    const window = doc.defaultView || doc.parentWindow

    const setSizes = () => {
      if (window.emailFieldHasFocus) {
        return
      }

      const inFrame = !(window === window.top)

      // because of some strange stuff about how sizes are
      // reported on mobile, we use the window.innerHeight/width
      // unless it's way bigger, in which case we use the
      // document.body.clientHeight/Width
      const pickDimension = (w, b) => {
        return w / b > 1.5 ? b : w
      }

      const innerHeight = inFrame
        ? window.innerHeight
        : pickDimension(window.innerHeight, document.body.clientHeight)
      const innerWidth = inFrame
        ? window.innerWidth
        : pickDimension(window.innerWidth, document.body.clientWidth)

      if (window && window.top) {
        window.top.lastSetInnerHeight = innerHeight
        window.top.defaultView = doc.defaultView
      }

      // can get very small numbers while CMS is loading,
      // so just ignore this one, as this method will get
      // called again with the right numbers
      if (innerHeight < 100) {
        return
      }

      const shouldRespondToDimensionChange = (
        width,
        previousWidth,
        height,
        previousHeight
      ) => {
        // if nothing has changed, then don't respond
        if (width === previousWidth && height === previousHeight) {
          return false
        }
        // if we've just gone fullscreen, then don't respond
        if (window.innerHeight === screen.height) {
          return false
        }
        return true
      }

      if (
        shouldRespondToDimensionChange(
          innerWidth,
          this.state.innerWidth,
          innerHeight,
          this.state.innerHeight
        )
      ) {
        const isPortrait = innerWidth < innerHeight
        const isMobile = Math.max(innerWidth, innerWidth) < 800

        this.setState({
          innerWidth: innerWidth,
          innerHeight: innerHeight,
          isMobile: isMobile,
          isPortrait: isPortrait
        })

        if (
          this.state.selectedItem &&
          this.state.isMobile &&
          this.state.isPortrait
        ) {
          this.scrollItemTitleToTop(this.state.selectedItem, 10)
        }
      }

      if (!this.state.sizesAreSet) {
        this.setState({ sizesAreSet: true })
      }
    }

    setInterval(setSizes, 500)

    window.addEventListener('resize', setSizes)

    setSizes()

    setupSoftKeyboardDetector(isOpen => {
      if (!isOpen) {
        window.scrollTo(0, 0)
        console.log('saw keybaord close')
      } else {
        console.log('scrolling to 250 because the handler saw the keybard open')
        window.scrollTo(0, 250)
        console.log('saw keyboard open')
      }
    })

    let lastTick = 0
    const scrollTick = elapsed => {
      const d = elapsed - lastTick
      lastTick = elapsed

      const transitions = Object.values(this.transitionsBySection).filter(
        t => t
      )

      for (const transition of transitions) {
        transition.tick(d)
      }

      if (transitions.length > 0) {
        this.forceUpdate()
      }

      // calculate the proportions of different sections
      // to pass to the background stuff
      // const top = this.getTitleFromTop() + this.getSpacingBetweenTitleAndContent()
      // const bottom = this.state.innerHeight + 100
      // const fractionalPosition = (position) => {
      //   return 1 - ((position - top) / (bottom - top))
      // }
      // this.fractionalPositions = {
      //   home: 1 - Math.abs(this.contentTops.title),
      //   about: 1 - Math.abs(this.contentTops.about),
      //   timeTop: fractionalPosition(this.contentTops.time),
      //   soundTop: fractionalPosition(this.contentTops.sound),
      //   bodyTop: fractionalPosition(this.contentTops.body)
      // }
    }

    timer(scrollTick)

    let lastWheelDetected = null

    this.main.current.addEventListener('wheel', e => {
      if (this.state.isMobile) {
        return
      }

      if (lastWheelDetected && new Date() - lastWheelDetected < 1000) {
        return false
      }
      lastWheelDetected = new Date()
      this.changeSection(e.deltaY > 0 ? 1 : -1)
    })

    window.addEventListener('keyup', e => {
      if (
        e.code === 'ArrowUp' ||
        e.code === 'ArrowDown' ||
        e.code === 'ArrowLeft' ||
        e.code === 'ArrowRight'
      ) {
        e.preventDefault()
      }
    })

    window.addEventListener('keydown', e => {
      // ignore repeat keystrokes
      if (e.repeat) {
        return
      }
      switch (e.code) {
        case 'ArrowUp':
          this.changeSection(-1)
          e.preventDefault()
          break
        case 'ArrowDown':
          this.changeSection(+1)
          e.preventDefault()
          break
        default:
          // do nothing
          break
      }
    })

    const changeItemForSlug = slug => {
      const item = this.getItemBySlug(slug)
      if (item && item !== this.state.selectedItem) {
        if (this.state.isMobile && this.state.isPortrait) {
          this.scrollItemTitleToTop(item)
        }

        this.setState({
          selectedItem: item
        })
      }
    }

    if (window === window.top) {
      this.sectionHistory = new History('s')
      const h = this.sectionHistory.get()
      if (!h) {
        this.sectionHistory.set('home')
      }

      this.sectionHistory.on('change', section => {
        const slug = this.itemHistory.get()
        const item = this.getItemBySlug(slug)
        console.log('going to section, item', section, item)
        this.changeSection(section, item)
      })

      this.itemHistory = new History('i')

      this.itemHistory.on('change', slug => {
        changeItemForSlug(slug)
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      this.itemHistory &&
      prevState.selectedItem !== this.state.selectedItem
    ) {
      this.itemHistory.set(this.state.selectedItem?.slug, null, true)
    }

    if (prevState.sizesAreSet === false && this.state.sizesAreSet) {
      if (this.sectionHistory) {
        const historySection = this.sectionHistory.get()
        if (this.currentSection !== historySection) {
          const slug = this.itemHistory.get()
          const item = this.getItemBySlug(slug)
          this.changeSection(historySection, item)
        }
      }
    }
  }

  scrollItemTitleToTop (item, duration) {
    const {
      elItemTitle,
      elContentBox
    } = this.getTitleAndContentBoxElementsForItem(item)
    const transitionTitle = 'itemTitleScroll'
    const transition = createTransition({
      startValue: elContentBox.scrollTop,
      endValue: elItemTitle.offsetTop + 10,
      ease: easeQuad,
      duration: duration !== undefined ? duration : TRANSITION_DURATION,
      delay: 0,
      setter: value => {
        elContentBox.scrollTop = value
      },
      onEnd: () => {
        this.removeTransition(transitionTitle)
      }
    })
    this.transitionsBySection[transitionTitle] = transition
  }

  scrollToTopOfCurrentSection () {
    const {
      elItemTitle,
      elContentBox
    } = this.getTitleAndContentBoxElementsForItem(this.state.selectedItem)

    const transitionTitle = 'itemTitleScroll'
    const transition = createTransition({
      startValue: elContentBox.scrollTop,
      endValue: 0,
      ease: easeQuad,
      duration: TRANSITION_DURATION,
      delay: 0,
      setter: value => {
        elContentBox.scrollTop = value
      },
      onEnd: () => {
        this.removeTransition(transitionTitle)
      }
    })
    this.transitionsBySection[transitionTitle] = transition
  }

  handleItemClick (item, el) {
    const nextSelected = item === this.state.selectedItem ? null : item
    const { isMobile, isPortrait } = this.state
    if (isMobile && isPortrait) {
      if (nextSelected) {
        this.scrollItemTitleToTop(nextSelected)
      } else {
        this.scrollToTopOfCurrentSection()
      }
    }

    this.setState({
      firstItemSelected: true,
      selectedItem: nextSelected,
      showLastPieceFirst: false
    })
  }

  createSectionTransition (
    section,
    position,
    duration = TRANSITION_DURATION,
    delay = 0,
    endAction
  ) {
    const transition = createTransition({
      startValue: this.contentTops[section],
      endValue: position,
      ease: easeQuad,
      duration,
      delay,
      setter: value => {
        this.contentTops[section] = value
      },
      onEnd: () => {
        this.removeTransition(section)
        if (endAction) {
          endAction()
        }
      }
    })
    this.transitionsBySection[section] = transition
  }

  getTitleFromBottom () {
    const { isMobile, isPortrait } = this.state
    return isMobile && isPortrait
      ? TITLE_FROM_BOTTOM_MOBILE_PORTRAIT
      : TITLE_FROM_BOTTOM
  }

  getTitleFromTop () {
    const { isMobile, isPortrait } = this.state
    if (isMobile) {
      return isPortrait
        ? TITLE_FROM_TOP_MOBILE_PORTRAIT
        : TITLE_FROM_TOP_MOBILE_LANDSCAPE
    }
    return TITLE_FROM_TOP
  }

  getSpacingBetweenTitleAndContent () {
    const { isMobile, isPortrait } = this.state
    if (isMobile) {
      return isPortrait
        ? SPACING_BETWEEN_TITLE_AND_CONTENT_MOBILE_PORTRAIT
        : SPACING_BETWEEN_TITLE_AND_CONTENT_MOBILE_LANDSCAPE
    }
    return SPACING_BETWEEN_TITLE_AND_CONTENT
  }

  createScrollToTopTransition (section, transitionEndAction) {
    return this.createSectionTransition(
      section,
      0,
      TRANSITION_DURATION,
      0,
      transitionEndAction
    )
  }

  createScrollToBottomTransition (section) {
    return this.createSectionTransition(
      section,
      1,
      TRANSITION_DURATION,
      0,
      () => {
        const el = {
          time: this.timeContent,
          sound: this.soundContent,
          body: this.bodyContent
        }[section].current.children[0]
        el.scrollTop = 0
      }
    )
  }

  removeTransition (section) {
    this.transitionsBySection[section] = null
  }

  changeSection (move, item) {
    if (move === null) {
      return
    }

    let nextSection
    // handle numerical section movement (+1, -1)
    if (typeof move === 'number') {
      const i = sectionsInOrder.indexOf(this.currentSection)
      const iNext = i + move
      // if out of bounds, just ignore
      if (iNext < 0 || iNext > sectionsInOrder.length - 1) {
        return
      }
      nextSection = sectionsInOrder[iNext]
    } else {
      // if clicking on time or body when an item is selected in
      // mobile portrait mode, it just deselects the item rather than
      // closing the section
      const { isMobile, isPortrait, selectedItem } = this.state
      if (
        isMobile &&
        isPortrait &&
        selectedItem &&
        ['time', 'body'].includes(move) &&
        move === this.currentSection
      ) {
        this.scrollToTopOfCurrentSection()
        this.setState({ selectedItem: null })
        return
      }
      nextSection = move
    }

    if (nextSection === this.currentSection) {
      if (nextSection === 'home') {
        nextSection = 'about'
      } else {
        nextSection = 'home'
      }
    }

    const switchingFromLowerSection = lowerSections.includes(
      this.currentSection
    )

    // now we know we are definitely switching section
    if (switchingFromLowerSection) {
      this.scrollSectionToBottom(this.currentSection)
    }

    const isLongTransition =
      (switchingFromLowerSection && nextSection === 'about') ||
      (this.currentSection === 'about' && lowerSections.includes(nextSection))
    const titleTransitionDuration = isLongTransition
      ? TRANSITION_DURATION * 2
      : TRANSITION_DURATION
    const aboutDelay = switchingFromLowerSection ? TRANSITION_DURATION : 0

    if (lowerSections.includes(nextSection)) {
      this.scrollSectionToTop(nextSection, item)
      this.scrollTitleTo(-1, titleTransitionDuration)
      this.scrollAboutTo(-1)
    } else if (nextSection === 'home') {
      this.scrollTitleTo(0, titleTransitionDuration)
      this.scrollAboutTo(-1)
    } else {
      // next section is about
      this.scrollTitleTo(+1, titleTransitionDuration)
      this.scrollAboutTo(0, TRANSITION_DURATION, aboutDelay)
    }

    this.currentSection = nextSection

    if (this.sectionHistory) {
      this.sectionHistory.set(this.currentSection)
    }

    this.setState({
      selectedItem: null,
      downloadStreamIsOpen: false
    })
  }

  getListContainingItem (item) {
    if (!item) {
      return null
    }
    const { timeItems, bodyItems } = this.props
    const lists = [timeItems, bodyItems]
    for (const list of lists) {
      if (list.includes(item)) {
        return list
      }
    }
  }

  getTitleAndContentBoxElementsForItem (item) {
    const { timeItems, bodyItems } = this.props
    let elContentBox
    let elItemTitle
    if (timeItems.includes(item)) {
      elContentBox = this.timeContent.current.children[0]
    } else if (bodyItems.includes(item)) {
      elContentBox = this.bodyContent.current.children[0]
    }
    if (elContentBox) {
      elItemTitle = elContentBox.querySelector('#item_title_' + item.slug)
    }
    return { elItemTitle, elContentBox }
  }

  handleImageForAudioSelect (imageUrl) {
    this.setState({
      imageForAudioUrl: imageUrl
    })
  }

  handleItemChange (move) {
    const { selectedItem } = this.state
    const itemList = this.getListContainingItem(selectedItem)

    let index = itemList.indexOf(selectedItem)
    index += move
    if (index < 0 || index > itemList.length - 1) {
      return
    }

    const newSelectedItem = itemList[index]

    this.setState({
      firstItemSelected: true,
      selectedItem: newSelectedItem,
      showLastPieceFirst: move < 0
    })

    this.scrollItemTitleToTop(newSelectedItem)
  }

  handleToggleDownloadStream () {
    this.setState({
      downloadStreamIsOpen: !this.state.downloadStreamIsOpen
    })
  }

  getItemBySlug (slug) {
    const { timeItems, sounds, bodyItems } = this.props
    const allItems = timeItems.concat(sounds).concat(bodyItems)
    const item = allItems.find(i => i.slug === slug)
    return item
  }

  scrollSectionToTop (section, item) {
    this.createScrollToTopTransition(section, () => {
      let selectedItem = null
      const { isMobile, isPortrait } = this.state
      if (!(isMobile && isPortrait)) {
        // automatically select items when opening the list
        if (section === 'body') {
          selectedItem = this.props.bodyItems[0]
        }
        if (section === 'time') {
          selectedItem = getDefaultItem(this.props.timeItems)
        }
        // if user hasn't clicked anything yet, then take
        // the item from the history, if present
        if (!this.state.firstItemSelected) {
          const slug = this.itemHistory.get()
          if (slug) {
            const item = this.getItemBySlug(slug)
            if (item) {
              selectedItem = item
            }
          }
        }
      }
      if (item) {
        selectedItem = item
        if (isMobile && isPortrait) {
          this.scrollItemTitleToTop(item)
        }
      }
      this.setState({
        selectedItem,
        firstItemSelection: true,
        showLastPieceFirst: false
      })
    })
  }

  scrollSectionToBottom (section) {
    this.createScrollToBottomTransition(section)
  }

  scrollTitleTo (position, duration) {
    this.createSectionTransition('title', position, duration)
  }

  scrollAboutTo (position, duration, delay) {
    this.createSectionTransition('about', position, duration, delay)
  }

  fakeHover (e) {
    const t = e.target
    t.classList.add('fakehover')
    setTimeout(() => {
      t.classList.remove('fakehover')
    }, 300)
  }

  handleScrollToHome (goToTitle) {
    if (this.contentTops.about === 0 && !goToTitle) {
      this.headingHome = false
      this.transitions.push(
        this.createAboutTransition(this.state.innerHeight / 1.5)
      )
    } else {
      this.headingHome = true
      this.transitions.push(this.createAboutTransition(0))
    }

    // TODO : come back to this
    for (const s of sectionsInOrder) {
      if (this.contentTops[s] < this.state.innerHeight) {
        this.transitions.push(this.createScrollToBottomTransition(s))
      }
    }
  }

  getContentHeight (section) {
    const contentRef = this.refsBySection[section]
    return contentRef.current ? contentRef.current.offsetHeight : 500
  }

  render () {
    const {
      about1,
      about2,
      about3,
      timeItems,
      sounds,
      bodyItems,
      backgrounds
    } = this.props

    const {
      innerWidth,
      innerHeight,
      isMobile,
      isPortrait,
      selectedItem,
      showLastPieceFirst,
      imageForAudioUrl,
      downloadStreamIsOpen,
      sizesAreSet
    } = this.state

    const contentTops = this.contentTops

    const titleBottomFromAboutScale = scaleLinear()
      .domain([-1, 0])
      .range([this.getTitleFromBottom(), -90])
      .clamp(true)

    const contentTopScale = scaleLinear()
      .domain([0, 1])
      .range([
        this.getTitleFromTop() + this.getSpacingBetweenTitleAndContent(),
        this.state.innerHeight + 100
      ])

    const titleTop = section => {
      const contentTop = contentTopScale(contentTops[section])
      const contentHeight = this.getContentHeight(section)
      const contentBottomY = contentTop + contentHeight
      const fromBottom = titleBottomFromAboutScale(
        contentTopScale(this.contentTops.about)
      )

      const min = Math.min(
        this.getTitleFromTop(),
        contentBottomY + TITLE_BELOW_CONTENT_SPACING
      )

      let result = Math.max(
        min,
        Math.min(
          contentTop - this.getSpacingBetweenTitleAndContent(),
          innerHeight - fromBottom
        )
      )

      if (result < -60) {
        result = Math.max(result + innerHeight + 60, innerHeight - fromBottom)
      }
      return result
    }

    const titleFontSize = isMobile && isPortrait ? '40px' : null
    const appStyle = {
      width: innerWidth,
      height: innerHeight,
      transition: 'opacity 1s'
    }

    if (!sizesAreSet) {
      return (
        <div
          className='app'
          ref={this.main}
          style={{ ...appStyle, opacity: sizesAreSet ? 1 : 0 }}
        />
      )
    }

    const pieceStyle = getPieceStyle({
      innerHeight,
      innerWidth,
      isMobile,
      isPortrait
    })

    const titlesBottomPadding =
      innerHeight -
      this.getTitleFromTop() -
      this.getSpacingBetweenTitleAndContent() -
      120

    const renderPieces = (inline = false) => (
      <Pieces
        inline={inline}
        pieces={selectedItem.pieces}
        showLastPieceFirst={showLastPieceFirst}
        innerHeight={innerHeight}
        innerWidth={innerWidth}
        onItemChange={this.handleItemChange}
        isMobile={isMobile}
        isPortrait={isPortrait}
      />
    )

    if (isMobile && !isPortrait) {
      return (
        <div
          className='app'
          ref={this.main}
          style={{ ...appStyle, opacity: sizesAreSet ? 1 : 0 }}
        >
          <Backgrounds
            backgrounds={backgrounds}
            position={this.currentSection}
          />
          <Title
            isMobile={isMobile}
            isPortrait={isPortrait}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            position={0.3}
          />
        </div>
      )
    }

    return (
      <div
        className='app'
        ref={this.main}
        style={{ ...appStyle, opacity: sizesAreSet ? 1 : 0 }}
      >
        <Backgrounds backgrounds={backgrounds} position={this.currentSection} />
        {this.currentSection !== 'sound' &&
          selectedItem &&
          selectedItem.pieces &&
          !(isMobile && isPortrait) &&
          renderPieces()}
        {this.currentSection === 'sound' && imageForAudioUrl && (
          <div style={{ ...pieceStyle, zIndex: 0 }}>
            <Piece
              height={Math.round(innerHeight / 2)}
              width={Math.round(pieceStyle.width)}
              piece={{
                type: 'media',
                mediaUrl: imageForAudioUrl,
                fadeEdges: true
              }}
            />
          </div>
        )}
        {selectedItem && selectedItem.description && (
          <Description
            onRight={this.currentSection === 'time'}
            description={selectedItem.description}
            innerHeight={innerHeight}
            innerWidth={innerWidth}
            isMobile={isMobile}
            isPortrait={isPortrait}
            renderPieces={renderPieces}
          />
        )}
        <div
          ref={this.timeContent}
          style={{
            left: '10px',
            textAlign: 'left',
            position: 'absolute',
            top: contentTopScale(contentTops.time) - 30,
            overflowY: 'auto',
            opacity: 0.8
          }}
        >
          <ContentBox
            fullHeight
            innerHeight={innerHeight}
            isMobile={isMobile}
            isPortrait={isPortrait}
            preventScrolling={isMobile && isPortrait && selectedItem}
          >
            <ItemTitles
              items={timeItems}
              isMobile={isMobile}
              isPortrait={isPortrait}
              onItemClick={(item, el) => this.handleItemClick(item, el)}
              selected={selectedItem}
              bottomPadding={titlesBottomPadding}
            />
          </ContentBox>
        </div>

        <div
          ref={this.soundContent}
          style={{
            textAlign: 'center',
            position: 'absolute',
            top: contentTopScale(contentTops.sound) - 30,
            width: innerWidth
          }}
        >
          <ContentBox
            innerHeight={innerHeight}
            isMobile={isMobile}
            isPortrait={isPortrait}
            fullHeight
          >
            <AudioPlayers
              sounds={sounds}
              innerWidth={innerWidth}
              innerHeight={innerHeight}
              onImageSelect={this.handleImageForAudioSelect}
            />
            <DownloadStream
              innerWidth={innerWidth}
              isOpen={downloadStreamIsOpen}
              onToggleDownloadStream={this.handleToggleDownloadStream}
            />
          </ContentBox>
        </div>

        <div
          ref={this.bodyContent}
          style={{
            right: '10px',
            textAlign: 'right',
            position: 'absolute',
            top: contentTopScale(contentTops.body) - 30
          }}
        >
          <ContentBox
            fullHeight
            innerHeight={innerHeight}
            isMobile={isMobile}
            isPortrait={isPortrait}
            preventScrolling={isMobile && isPortrait && selectedItem}
          >
            <ItemTitles
              items={bodyItems}
              isMobile={isMobile}
              isPortrait={isPortrait}
              onItemClick={(item, el) => this.handleItemClick(item, el)}
              selected={selectedItem}
              onRight
              bottomPadding={titlesBottomPadding}
            />
          </ContentBox>
        </div>

        <h1
          onClick={e => {
            this.changeSection('time')
            this.fakeHover(e)
          }}
          style={{
            top: titleTop('time'),
            fontSize: titleFontSize,
            ...timeTitleStyle
          }}
        >
          time
        </h1>
        <h1
          onClick={e => {
            this.changeSection('sound')
            this.fakeHover(e)
          }}
          style={{
            top: titleTop('sound'),
            fontSize: titleFontSize,
            ...soundTitleStyle
          }}
        >
          sound
        </h1>
        <h1
          onClick={e => {
            this.changeSection('body')
            this.fakeHover(e)
          }}
          style={{
            top: titleTop('body'),
            fontSize: titleFontSize,
            ...bodyTitleStyle
          }}
        >
          body
        </h1>

        <AboutSection
          innerHeight={innerHeight}
          innerWidth={innerWidth}
          position={contentTops.about}
          columns={[about1, about2, about3]}
          isMobile={isMobile}
          isPortrait={isPortrait}
        />
        <Title
          isMobile={isMobile}
          isPortrait={isPortrait}
          innerWidth={innerWidth}
          innerHeight={innerHeight}
          position={contentTops.title}
          onClick={() => {
            this.changeSection('home')
          }}
        />
        {/* <SizeDebugger {...{ isMobile, isPortrait, innerWidth, innerHeight }} /> */}
      </div>
    )
  }
}
